
  var editorScriptEntry = require('/home/builduser/work/3c1b4b320a39742d/packages/pro-gallery-tpa/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/3c1b4b320a39742d/packages/pro-gallery-tpa/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://d2da005893e64a638a4aa6cb7f0dd60c@sentry.wixpress.com/3939',
        id: 'd2da005893e64a638a4aa6cb7f0dd60c',
        projectName: 'pro-gallery-tpa',
        teamName: 'photography',
        errorMonitor: true,
      };
      const experimentsConfig = {"scopes":["pro-gallery"],"centralized":true};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/3c1b4b320a39742d/packages/pro-gallery-tpa/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","app.create.errorMessage.title":"Couldn’t add Wix Pro Gallery","app.create.errorMessage.description":"There was a technical issue on our end. Wait a few moments and try again.","Accessibility_Tooltip":"Use the keyboard arrows to navigate through the gallery.","Accessibility_Left_Gallery":"Out of gallery","Acessibility_Gallery_Navigate":"Press the Enter key and then use the arrow keys to navigate through the gallery. ","Gallery_Empty_Title":"Add your images,","Gallery_Empty_Title2":"video and text.","Gallery_Empty_Description":"Start adding media to this gallery.","Gallery_Empty_Description2":"Click Manage Media to get started"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'pro-gallery-tpa',
        appDefId: '14271d6f-ba62-d045-549b-ab972ae1f70e',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/3c1b4b320a39742d/packages/pro-gallery-tpa/src/components/ProGalleryBob/editor.controller.ts');

      const model_0 = require('/home/builduser/work/3c1b4b320a39742d/packages/pro-gallery-tpa/src/components/ProGalleryBob/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "14271d6f-ba62-d045-549b-ab972ae1f70e-myzur";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"14271d6f-ba62-d045-549b-ab972ae1f70e-myzur": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14271d6f-ba62-d045-549b-ab972ae1f70e-myzur", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
